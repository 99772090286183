<template>

<Header />



<section>
	
	<div class="container">

		<div class="row">
			
			<div class="col-md-2"></div>
			<div class="col-md-8">

				<div class="row">

					<div class="col-md-6 col-6">
						<div>
					<h6>Welcome, {{this.$store.state.first_name}}</h6>
				</div>
					</div>



					<div class="col-md-6 col-6" style="text-align:right;">
						<router-link to="/apartments" class="btn btn-outline-success"> Book Apartment + </router-link>
					</div>
					
				</div>



				<div class="table-responsive">

					<table class="table">
						<thead>
							<tr>
								<th>Order#</th>
								<th> Check in date </th>
								<th> Check out date </th>
								<th> Apartment </th>
								<th> Action </th>
							</tr>
						</thead>

						<tbody>
							
							<tr v-for="o in orders">
								<td> {{o.order_number}}  </td>
								<td> {{o.check_in_date}}  </td>
								<td> {{o.check_out_date}}  </td>
								<td> {{o.apartment_name}}  </td>
								<td>
									<router-link :to=" { name : 'order-details', params : { id : o.id } } " class="btn btn-1"> View </router-link>
								</td>
							</tr>

						</tbody>

					</table>
					
				</div>




			</div>

		</div>
		
	</div>


</section>




<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'

	
	export default{
		name : 'home',
		components : {Header,Footer},
		data(){
			return{
				orders : []
			}
		},

		methods : {

			async get_orders(){
				const res  = await axios.get(this.$store.state.url+'api/my-orders/'+this.$store.state.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.orders = res 
			}

		},

		created(){
			this.get_orders()
		}
	}

</script>