<template>


<section>
	

	<div class="container">

		<div class="row">

			<div class="col-md-6" style="background-image: url('/assets/images/portrait-interracial-couple-home (1).jpg'); background-size: cover; background-position: center; "></div>

			<div class="col-md-4">
				
				<div style="height:100vh; padding-top: 100px;">

					<div>
						<router-link to="/" class="btn p-0"> <i class="bi bi-chevron-left color-1"></i> Back to main site</router-link>
					</div>

					<div style="padding-bottom: 20px; text-align: center;">
						<img src="/assets/images/new-logo.png" style="height: 70px;">
						<h3><b>Welcome Back!</b></h3>
						<h4>Login</h4>
					</div>
					
					<form @submit.prevent="login()">
					
						<div> 
							<label>Email address <span class="text-danger">*</span> </label>
							<input v-model="email" class="form-control" placeholder="Enter email address" type="text">
						</div>

						<div class="mt-3"> 
							<label>Password <span class="text-danger">*</span> </label>
							<input v-model="password" class="form-control" placeholder="Enter password" type="password">
						</div>

						<div class="mt-3" style="text-align: left;">
							<div class="row">
								<div class="col-md-6 col-6">
								<input type="checkbox">	Remember me
								</div>

								<div class="col-md-6 col-6" style="text-align:right;">
									<router-link class="btn p-0" to="/forgot-password">Forgot password </router-link>
								</div>
							</div>
						</div>

						<div class="mt-3">
							<button :disabled="disabled" class="btn btn-1 w-100">{{text}} </button>
						</div>


						<div class="mt-3">
							<router-link class="btn p-0" to="/register">Don't have an account? <span class="text-primary">Register</span> </router-link>
						</div>

					</form>

				</div>

			</div>
			
			
		</div>
		
	</div>


</section>


</template>



<script>
	import axios from 'axios'
	import Swal from 'sweetalert2'

	export default{
		name  : 'login',
		data(){
			return{
				email : '',
				password : '',
				disabled : false,
				text : 'Login'
			}
		},

		methods : {
			async login(){
				if(!this.email){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Email is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.password){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Password is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				this.disabled = true
				this.text = 'Please wait...'


				const res = await axios.post(this.$store.state.url+'api/customer-login',{
					email : this.email,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				
				if(res.length==0){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Wrong email or password",
					  showConfirmButton: false,
					  timer: 1500
					});
				}else{
					res.forEach((data)=>{

					this.$store.state.id = data.id
					localStorage.setItem('id',data.id)

					this.$store.state.first_name = data.first_name
					localStorage.setItem('first_name',data.first_name)

					this.$store.state.last_name = data.last_name
					localStorage.setItem('last_name',data.last_name)

					this.$store.state.email = data.email
					localStorage.setItem('email',data.email)

					this.$store.state.phone = data.phone
					localStorage.setItem('phone',data.phone)

					this.$store.state.logged_in = 'yes'
					localStorage.setItem('logged_in','yes')

					this.$router.push('/dashboard')



				})
				}

				this.disabled = false
				this.text = 'Login'
				

			}
		}
	}

</script>